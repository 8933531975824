<template>
    <div>
        <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
            <v-row justify="center" class="mb-8">
                <v-col class="pl-0 pr-0 text-left" cols="12">
                    <h1 class="candidate-details__title">Ganadores Rappi Ranking</h1>
                </v-col>
                <!-- Filtro por año -->
                <v-row justify="end" class="mb-8">
                    <v-col class="pl-0 pr-0 text-left" cols="6">
                        <v-select v-model="selectedYear" :items="years" label="Seleccionar Año" outlined dense
                            class="mb-4" @change="getGanadores" />

                    </v-col>
                </v-row>

                <v-timeline align="start" class="timeline-container">
                    <!-- Verificar si hay items, si no mostrar mensaje -->
                    <div v-if="items.length === 0">
                        <v-alert type="info" class="text-center">
                            No se encontraron ganadores para el año seleccionado
                        </v-alert>
                    </div>

                    <v-timeline-item v-for="(item, i) in items" :key="i" dot-color="white" fill-dot large
                        class="custom-timeline-item">
                        <template v-slot:opposite>
                            <span style="font-size: 20px; font-weight: 600;">{{ item.periodo }}</span>
                        </template>

                        <template v-slot:icon>
                            <v-img :src="item.icon" width="35" height="35"></v-img>
                        </template>

                        <v-card>
                            <v-card-title class="text-h6 d-flex justify-center"
                                style="background: #fc3c24; color: #fff; cursor: pointer;">
                                Ganadores Rappi Delivery Ranking
                            </v-card-title>

                            <v-card-text class="bg-white text-center justify-content-center align-items-center"
                                style="padding: 20px;">
                                <!-- Contenedor con Grid -->
                                <div class="personas-grid">
                                    <div v-for="(persona, index) in firstTenPersons(item).slice(0, 10)"
                                        :key="'persona-' + index" class="persona-item">
                                        <v-img @click="openDialog(persona)" width="60" height="60"
                                            style="border-radius: 50px; border: 2px solid; border-color: #fc3c24;"
                                            :src="persona.photo" class="custom-image-roudend-winner" />
                                    </div>
                                </div>
                                <br>
                                <v-btn v-if="item.personas.length > 10" @click="openModal(item)"
                                    class="mt-2 custom-btn">
                                    Ver más
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-timeline-item>
                </v-timeline>


            </v-row>
        </div>

        <v-overlay :value="loadingAll">
            <v-progress-circular :size="120" color="#466BE3" indeterminate>
                {{ $translate("general.loading") }}
            </v-progress-circular>
        </v-overlay>

        <!-- Diálogo para mostrar información del candidato -->
        <v-dialog v-model="dialog" max-width="400px" content-class="custom-popup-reconocimientos super-stars">
            <v-card>
                <v-card-text>
                    <div class="container-content-card">
                        <v-img :src="selectedItem.photo" width="125" height="125" class="mb-2"></v-img>
                        <div class="content-card text-center">
                            <p style="font-size: 24px; text-transform: capitalize;">{{ selectedItem.nombre }} {{
                                selectedItem.apellido }}</p>
                            <p style="font-size: 16px;">{{ selectedItem.cargo }}</p>
                        </div>
                    </div>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Api from "@/utils/api";

export default {
    data() {
        return {
            origin: window.location.origin,
            token: "", // Guardamos el token
            infoToken: {
                document: "", // Almacena información del token
            },
            name: "", // Almacena el nombre del usuario

            loadingAll: false,
            dialog: false,
            selectedItem: {},
            modalVisible: false,

            // Inicializamos items como un arreglo vacío
            items: [],  // Estaba con datos fijos, ahora empieza vacío
            selectedYear: new Date().getFullYear(), // Año seleccionado por defecto
            years: [], // Aquí almacenaremos los años disponibles para el filtro

        };
    },

    computed: {
        firstTenPersons() {
            return (item) => item.personas.slice(0, 10);  // Limitar a las primeras 10 personas
        },
    },
    methods: {
        // Método para obtener el token de autenticación
        getToken() {
            var token = localStorage.getItem("token");
            var tokenb64 = localStorage.getItem("tokenB64");
            this.token = tokenb64;
            var div = token.split(".");
            if (div.length >= 2) {
                this.infoToken = JSON.parse(atob(div[1]));
                var nameList = this.infoToken.fullName.split(" ");

                if (nameList.length > 1) {
                    if (nameList[0] != "") {
                        this.name = nameList[0];
                    } else {
                        this.name = nameList[1];
                    }
                } else {
                    this.name = "";
                }
            } else {
                this.infoToken = {
                    document: "",
                };
            }
        },

        getGanadores() {
            this.loadingAll = true;

            // Crear el cuerpo de la solicitud (data)
            const requestBody = JSON.stringify({
                programa_id: 2,  // ID del programa (2: Rappi ranking)
                limit: 0,  // Ajusta según tus necesidades
                user_id: 0,  // ID de usuario (debería ser dinámico si es necesario)
                vigencia: this.selectedYear, // Año seleccionado
            });

            // Realizar la solicitud
            Api.Reconocimientos()
                .getGanadoresPrograma(this.token, requestBody)  // Pasamos el token y el body JSON
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`Error: ${res.statusText}`);
                    }
                    return res.json();  // Parseamos la respuesta como JSON
                })
                .then((response) => {
                    if (response && response.data) {
                        // Crear un objeto para agrupar por periodo
                        const groupedItems = {};

                        // Agrupar los ganadores por periodo
                        response.data.forEach(item => {
                            const periodoNombre = item.periodo.nombre;  // Nombre del periodo (ej. "Febrero")

                            // Si ya existe el grupo para este periodo, agregamos el ganador
                            if (!groupedItems[periodoNombre]) {
                                groupedItems[periodoNombre] = {
                                    periodo: periodoNombre,
                                    campaña: item.programa.nombre,  // Nombre de la campaña
                                    personas: [],  // Array para almacenar los ganadores
                                    icon: require('@/assets/icon/star-icon-red.svg'),  // El ícono sigue siendo el mismo
                                };
                            }

                            // Agregar la persona al grupo del periodo correspondiente
                            groupedItems[periodoNombre].personas.push({
                                nombre: item.user.full_name,
                                cargo: item.cargo,
                                photo: item.user.img,
                            });
                        });

                        // Ahora transformamos los objetos agrupados en un array para mostrarlos
                        this.items = Object.values(groupedItems);
                    } else {
                        console.error("Respuesta no válida:", response);
                        this.items = []; // Si no hay datos, establecemos un arreglo vacío
                    }

                    this.loadingAll = false;
                })
                .catch((error) => {
                    console.error("Error al obtener los ganadores:", error);
                    this.items = []; // En caso de error, también vaciamos los items
                    this.loadingAll = false;
                });
        },


        // Obtener los años disponibles para el filtro
        fetchYears() {
            const currentYear = new Date().getFullYear();
            this.years = [];
            for (let i = 0; i <= 5; i++) {
                this.years.push(currentYear - i);
            }
        },


        openDialog(persona) {
            this.selectedItem = persona;  // Asignamos la persona seleccionada al objeto `selectedItem`
            this.dialog = true;  // Abrimos el diálogo de la persona seleccionada
        },

        openModal(item) {
            // Guardamos los datos del periodo y los ganadores en localStorage
            localStorage.setItem('selectedPeriod', item.periodo);  // Guardamos el periodo
            localStorage.setItem('selectedWinners', JSON.stringify(item.personas));  // Guardamos las personas ganadoras
            localStorage.setItem('selectedProgramName', item.campaña);  // Guardamos el nombre del programa

            // Simulamos la carga de datos (por ejemplo, de un backend)
            this.loadingAll = true;

            setTimeout(() => {
                this.loadingAll = false;
                // Redirigimos a la ruta allWinners
                this.$router.push({ name: 'allWinners' });
            }, 1000); // Tiempo de carga simulado
        },


        getData() {
            this.loadingAll = true;
            setTimeout(() => this.loadingAll = false, 2000); // Simula el tiempo de carga
        },
    },

    mounted() {
        this.getData();  // Simula la carga de datos al montar el componente
        this.fetchYears();  // Llamamos a fetchYears para llenar los años disponibles
        this.getGanadores();  // Llama a la API para cargar los ganadores
    },

    created() {
        this.getToken();  // Obtener el token al crear el componente
    },

};
</script>




<style scoped>
.timeline-container {
    width: 100%;
}

.custom-timeline-item>>>.v-timeline-item__inner-dot {
    background: #fff !important;
}

.custom-timeline-item>>>.v-timeline-item__inner-dot .v-image .v-image__image {
    background-size: contain !important;
}

.campain-card {
    font-size: 1.5rem;
    font-weight: 500;
    color: #fc3c24;
}

.v-dialog__content>>>.custom-popup-reconocimientos.super-stars {
    border-radius: 25px !important;
}

.custom-popup-reconocimientos>>>.v-dialog {
    border-radius: 25px !important;
}

.custom-popup-reconocimientos>>>.v-card {
    border-radius: 25px !important;
}

.custom-popup-reconocimientos .v-card {
    position: relative;
    height: 550px !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #fc3c24 !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.custom-popup-reconocimientos .v-card::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-popup-reconocimientos .v-card::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}



.container-content-card {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.container-content-card .v-image {
    border: 4px solid #fff;
    border-radius: 50% !important;
    margin-bottom: 35px !important;
}

.container-content-card .content-card {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: start;
}


.custom-carousel-winners ::v-deep .v-window-item {
    width: 400px;
    margin: auto;
}

.custom-carousel-winners ::v-deep .v-window-item .v-card {
    position: relative;
    height: 100% !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #fc3c24 !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.custom-carousel-winners ::v-deep .v-window-item .v-card::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.custom-carousel-winners ::v-deep .v-window-item .v-card::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.custom-btn {
    background: #fc3c24 !important;
    color: #fff !important;
}

.custom-image-roudend-winner:hover {
    cursor: pointer;
}

/* Contenedor que usa CSS Grid */
.personas-grid {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    /* 5 columnas de 20% de ancho cada una */
    grid-gap: 20px 10px;
    /* Espaciado entre los elementos */
    /* justify-content: center; Centra el grid */
    margin-top: 10px;
}

/* Asegura que las imágenes tengan el estilo deseado */
.custom-image-roudend-winner {
    border-radius: 50%;
    border: 2px solid #fc3c24;
}
</style>